import React, { useEffect, useState } from 'react';

import LoginMenu from '@apps/header/shared/multilabel/src/LoginMenu';
import MainMenu from '@apps/header/shared/multilabel/src/MainMenu';
import SecondaryMenu from '@apps/header/shared/multilabel/src/SecondaryMenu';
import TopMenu from '@apps/header/shared/multilabel/src/TopMenu';
import usePlaceholderComponentContent from '@apps/header/shared/multilabel/src/usePlaceholderComponentContent';
import { isNextAuthEnabled, useSession } from '@dxp-auth';
import { useRouter } from '@dxp-next';
import { Placeholder } from '@sitecore/common';
import { MainMenuRendering } from '@sitecore/types/MainMenu';
import { LanguageSelectorRendering } from '@sitecore/types/manual/LanguageSelector';
import { MainMenuRightRendering, TopMenuRendering } from '@sitecore/types/manual/NavigationBar';
import { SubMenuRendering } from '@sitecore/types/SubMenu';
import { Box, DesktopHeader, MobileHeader, SideNav, Stack, StickyHeader } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';

import LoginLink from './components/LoginLink';
import SearchChatBot from './components/SearchChatBot';
import { ProfileMenuContext } from './utils/context';

/*
 * Sitecore returns the "MainNavigation" component with some dynamic
 * placeholders. The default <Placeholder> renders the components inside that
 * dynamic placeholder. However, this has a few downsides, since sub
 * components...
 *
 * - are isolated from this main header component, complicating shared state.
 * - are lazy-loaded, resulting in extra requests and delayed interactivity.
 * - require extra entries in `sitecoreComponents.ts` in every container (same
 *   for new components)
 *
 * As an optimized but less flexible alternative the
 * `usePlaceholderComponentContent` hook looks up the rendering so the sub
 * component can be rendered directly inside the main component without a
 * <Placeholder>. If we'll need this hook somewhere else as well we should
 * promote it to a lib.
 *
 * The <Logo> still has a dynamic placeholder for the different labels.
 */

/**
 * This hook retrieves the fields of either of the megamenu placeholders. It will return the fields of MegaMenuV2 if
 * the current site already uses it, otherwise it will return the fields of MegaMenu (V1).
 *
 * This is a temporary solution to support both versions of the MegaMenu until the old version is deprecated.
 */
const useMegaMenuPlaceholderFields = (): SubMenuRendering | null => {
  const megaMenuFieldsV2 = usePlaceholderComponentContent<SubMenuRendering>('jss-main-navigation-left', 'MegaMenuV2');
  const megaMenuFieldsV1 = usePlaceholderComponentContent<SubMenuRendering>('jss-main-navigation-left', 'MegaMenu');

  return megaMenuFieldsV2 || megaMenuFieldsV1;
};

const Desktop = () => {
  const router = useRouter();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const subItems = usePlaceholderComponentContent<SubMenuRendering>('jss-secondary-navigation', 'SubMenu')?.fields
    .items;
  const isOpenDomain = !subItems || subItems.length === 0;
  const isMegaMenu = !!useMegaMenuPlaceholderFields();

  const topMenuContent = usePlaceholderComponentContent<TopMenuRendering>('jss-topheader', 'TopMenu');
  const topHeaderAvailable = topMenuContent?.fields.items;

  const handleRouteChange = () => setIsProfileMenuOpen(false);

  const subMenuContent = usePlaceholderComponentContent<SubMenuRendering>('jss-secondary-navigation', 'SubMenu');
  const subMenuAvailable = subMenuContent?.fields.items;

  //When there is not Top or Sub menu we are in the minimal header, in this case we do not show the login link.
  const isMinimalHeader = !topHeaderAvailable && !subMenuAvailable;

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [router]);

  return (
    <ProfileMenuContext.Provider value={{ isOpen: isProfileMenuOpen, setIsOpen: setIsProfileMenuOpen }}>
      <DesktopHeader>
        <TopMenu
          platform="desktop"
          languageSelector={topHeaderAvailable ? <Placeholder name="jss-header-lang" /> : null}
        />
        <DesktopHeader.Main
          logoLink={<Placeholder name="jss-logo" />}
          languageSelector={!topHeaderAvailable ? <Placeholder name="jss-header-lang" /> : null}
          profileLink={
            isNextAuthEnabled ? (
              <LoginLink
                isMinimalHeader={isMinimalHeader}
                setIsProfileMenuOpen={setIsProfileMenuOpen}
                isMyZone={!!subMenuAvailable}
              />
            ) : (
              <LoginMenu />
            )
          }
          searchComponent={<SearchChatBot platform="desktop" />}
          isOpenDomain={isOpenDomain}>
          {isMegaMenu ? <Placeholder name="jss-main-navigation-left" /> : <MainMenu />}
        </DesktopHeader.Main>
        <SecondaryMenu />
        <SideNav
          isCloseButtonVisible={false}
          isDesktop={true}
          isOpen={isProfileMenuOpen}
          setIsOpen={setIsProfileMenuOpen}>
          <Box paddingY={10} paddingX={6}>
            <Stack gap={6}>
              <Placeholder name="jss-side-navigation" />
            </Stack>
          </Box>
        </SideNav>
      </DesktopHeader>
    </ProfileMenuContext.Provider>
  );
};

const Mobile = () => {
  const router = useRouter();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const megaMenuFields = useMegaMenuPlaceholderFields();
  const isMegaMenu = !!megaMenuFields;

  const mainMenuTitle = usePlaceholderComponentContent<MainMenuRendering>('jss-main-navigation-left', 'MainMenu')
    ?.fields?.title?.value;
  const megaMenuTitle = megaMenuFields?.fields?.title?.value;
  const hamburgerMenuLabel = isMegaMenu ? megaMenuTitle : mainMenuTitle;

  const handleRouteChange = () => {
    setIsProfileMenuOpen(false);
    setIsHamburgerMenuOpen(false);
  };

  const profileStateHandler = (state: boolean) => {
    if (isHamburgerMenuOpen) setIsHamburgerMenuOpen(false);
    setIsProfileMenuOpen(state);
  };

  const hamburgerStateHandler = (state: boolean) => {
    if (isProfileMenuOpen) setIsProfileMenuOpen(false);
    setIsHamburgerMenuOpen(state);
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [router]);

  const serviceLink = usePlaceholderComponentContent<MainMenuRightRendering>('jss-main-navigation-right', 'LoginMenu')
    ?.fields?.selfServiceLink?.value;
  const profileMenuLabel = serviceLink?.text;

  const isLanguageListDropdown =
    usePlaceholderComponentContent<LanguageSelectorRendering>('jss-header-lang', 'LanguageSelector')?.params
      ?.RenderVariant === 'Dropdown';
  const isLoggedIn = !!useSession().data;
  return (
    <MobileHeader
      isHamburgerMenuOpen={isHamburgerMenuOpen}
      hamburgerMenuLabel={hamburgerMenuLabel}
      profileMenuLabel={profileMenuLabel}
      isProfileMenuOpen={isProfileMenuOpen}
      setHamburgerMenuOpen={hamburgerStateHandler}
      setProfileMenuOpen={profileStateHandler}
      logoLink={<Placeholder name="jss-logo" />}
      profileLink={serviceLink}
      isLoggedIn={isLoggedIn}
      languageSelector={isLanguageListDropdown ? <Placeholder name="jss-header-lang" /> : null}>
      <MobileHeader.HamburgerMenu isOpen={isHamburgerMenuOpen}>
        <TopMenu platform="mobile" />
        <SearchChatBot platform="mobile" setIsOpen={setIsHamburgerMenuOpen} />
        <MobileHeader.Main>
          {isMegaMenu ? <Placeholder name="jss-main-navigation-left" /> : <MainMenu />}
        </MobileHeader.Main>
        <MobileHeader.Bottom>
          <Placeholder name="jss-header-lang" />
        </MobileHeader.Bottom>
      </MobileHeader.HamburgerMenu>
      <SideNav isDesktop={false} isOpen={isProfileMenuOpen} setIsOpen={profileStateHandler} isCloseButtonVisible={true}>
        <Box paddingY={10} paddingX={6}>
          <Stack gap={6}>
            <Placeholder name="jss-side-navigation" />
          </Stack>
        </Box>
      </SideNav>
    </MobileHeader>
  );
};

const MainNavigation = () => {
  const isDesktop = useMediaQuery('lg');

  return (
    <>
      <StickyHeader>{isDesktop ? <Desktop /> : <Mobile />}</StickyHeader>
      <Placeholder name="jss-main-navigation-breadcrumbs" />
    </>
  );
};

export default MainNavigation;
