import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import LoginMenu from '@apps/header/shared/multilabel/src/LoginMenu';
import { ProfileMenuLink } from '@apps/header/shared/multilabel/src/ProfileMenuLink';
import usePlaceholderComponentContent from '@apps/header/shared/multilabel/src/usePlaceholderComponentContent';
import useSelfServiceAccount from '@apps/myzone/be/eneco/src/hooks/useSelfServiceAccount';
import { getXapiSiteApiProfile } from '@dc-be/client';
import useAuthenticatedDCBE from '@dc-be/hooks/useAuthenticatedDCBE';
import { useSession } from '@dxp-auth';
import { MainMenuRightRendering } from '@sitecore/types/manual/NavigationBar';
import { NavLink } from '@sparky';
import { ProfileIcon, SpinnerIcon } from '@sparky/icons';

type LoginLinkProps = {
  isMinimalHeader: boolean;
  isMyZone: boolean;
  setIsProfileMenuOpen: Dispatch<SetStateAction<boolean>>;
};

const LoginLink: FC<LoginLinkProps> = ({ isMinimalHeader, isMyZone, setIsProfileMenuOpen }) => {
  const content = usePlaceholderComponentContent<MainMenuRightRendering>('jss-main-navigation-right', 'LoginMenu');
  const { selectedAccount, unsafelySetSelectedAccount } = useSelfServiceAccount();
  const { data: session } = useSession() ?? {};
  const [displayName, setDisplayName] = useState(selectedAccount.alias);

  const {
    data: getProfileData,
    isLoading: isGetProfileLoading,
    error: isGetProfileError,
  } = useAuthenticatedDCBE(getXapiSiteApiProfile, {}, ['session'], session);

  useEffect(() => {
    setDisplayName(selectedAccount.alias);
  }, [selectedAccount.alias]);

  useEffect(
    function switchToProspect() {
      if (!isGetProfileLoading && getProfileData && selectedAccount.alias === '') {
        const name = getProfileData?.data?.displayName || '';
        setDisplayName(name);
        unsafelySetSelectedAccount({ crmAccountNumber: '', alias: name, role: 'owner' });
      }
    },
    [getProfileData, isGetProfileLoading, isGetProfileError, selectedAccount.alias, unsafelySetSelectedAccount],
  );

  if (isMinimalHeader) {
    return null;
  }

  if (isGetProfileLoading) {
    return <SpinnerIcon />;
  }

  if (isMyZone) {
    return <ProfileMenuLink isLoading={false} displayName={displayName} onOpen={() => setIsProfileMenuOpen(true)} />;
  }

  if (session) {
    return (
      <NavLink href={content?.fields.selfServiceLink.value.href} leftIcon={<ProfileIcon />}>
        {content?.fields.selfServiceLink.value.text}
      </NavLink>
    );
  }

  return <LoginMenu />;
};

export default LoginLink;
